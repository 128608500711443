.navbar-brand {
    display: block;
    width: rem-calc(162);
    margin: rem-calc(7 0 0);

    @include media-breakpoint-up(md) {
        width: rem-calc(252);
    }

    @include media-breakpoint-up(lg) {
        width: 100%;
    }
}

.header-bar {
    width: 100%;
    line-height: 1.05;
    letter-spacing: 0.02em;

    @include media-breakpoint-up(lg) {
        padding: rem-calc(12 0);
    }

    @include media-breakpoint-up(xl) {
        padding-bottom: rem-calc(6);
    }

    .container {
        @include media-breakpoint-up(lg) {
            display: flex;
            justify-content: space-between;
        }
    }
}

.header-bar-control {
    padding: rem-calc(16 0 12);
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-up(lg) {
        order: -1;
        padding: rem-calc(0 20 0 0);
        display: block;
        width: rem-calc(220);
    }

    @include media-breakpoint-up(xl) {
        width: rem-calc(272);
    }

    .group-togglers {
        margin: rem-calc(0 -6);

        @include media-breakpoint-up(md) {
            margin-top: rem-calc(15);
        }

        > * {
            margin: rem-calc(0 6);
        }
    }
}

.navbar-toggler,
.search-toggler {
    padding: 0;
    border: 0;
    background-color: transparent;
    position: relative;
    color: $primary;

    &.collapsed {
        .show-img {
            transform: scale(0) translate(-50%, -50%);
            opacity: 0;
        }

        .collapsed-img {
            transform: scale(1);
            opacity: 1;
        }
    }

    img,
    svg {
        @include animate (transform opacity);
    }

    .collapsed-img {
        transform: scale(0);
        opacity: 0;
    }

    .show-img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: scale(1) translate(-50%, -50%);
        opacity: 1;
    }
}

.navbar-toggler {
    border: 0;
    position: relative;

    &:focus {
        box-shadow: none;
    }
}

.header-bar-contact {
    background-color: $success;
    color: $white;
    margin: 0 (-$container-padding-x * 0.5);
    padding: rem-calc(7) $container-padding-x * 0.5 rem-calc(3);
    font-size: rem-calc(14);
    letter-spacing: 0.04em;

    @include media-breakpoint-up(sm) {
        font-size: rem-calc(17);
        padding-bottom: rem-calc(1);
    }

    @include media-breakpoint-up(lg) {
        background-color: transparent;
        color: inherit;
        margin: 0 0 0 auto;
        flex-grow: 1;
        max-width: 48.7%;
        padding: rem-calc(16 16 0);
        display: flex;
        font-size: $font-size-sm;
        letter-spacing: 0.05em;
    }

    @include media-breakpoint-up(xl) {
        font-size: $font-size-base;
    }

    .col {
        display: flex;
        justify-content: space-between;

        @include media-breakpoint-up(lg) {
            width: 60%;
            display: block;
            padding: rem-calc(0 15);
            flex: auto;
        }

        &:first-child {
            @include media-breakpoint-up(lg) {
                width: 36.8%;
            }
        }
    }

    .frame {
        + .frame {
            @include media-breakpoint-up(lg) {
                margin-top: rem-calc(16);
            }
        }
    }

    .text {
        @include media-breakpoint-up(lg) {
            display: block;
        }

        + .text {
            @include media-breakpoint-up(lg) {
                margin-top: rem-calc(5);
            }
        }
    }

    .text-with-svg {
        svg,
        img {
            margin-left: rem-calc(-21);
        }
    }

    .align-center-block {
        margin: 0 auto;
        padding: 0 0 0 rem-calc(15);

        @include media-breakpoint-up(lg) {
            margin: 0;
            padding-left: 0;
        }
    }
}

.header-bar-search {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: $body-bg;
    z-index: 10;

    @include media-breakpoint-up(lg) {
        width: rem-calc(278);
        text-align: right;
        position: static;
        background-color: transparent;
        padding: rem-calc(15 0 0);
    }
}

.header-list-user {
    @extend %listreset;
    display: flex;
    font-size: rem-calc(17);
    margin: rem-calc(0 -9 14);
    letter-spacing: 0.05em;

    @include media-breakpoint-up(lg) {
        margin-left: rem-calc(-7);
        margin-bottom: rem-calc(12);
    }

    li {
        margin: rem-calc(0 9);
    }
}

.header-bar-location {
    background-color: $primary;
    color: $white;
    font-size: rem-calc(16);
    font-weight: 500;
    padding: rem-calc(8) $container-padding-x * 0.5 rem-calc(1);
    margin: 0 (-$container-padding-x * 0.5);

    a {
        color: inherit;
    }
}

.nav-bar {
    background-color: $primary;
    color: $white;
    font-size: rem-calc(21);
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 10;

    @include media-breakpoint-up(lg) {
        position: static;
    }

    a {
        color: inherit;

        &:focus,
        &:hover {
            color: $warning;
        }
    }
}

.nav-bar-frame {
    padding: rem-calc(0 0 20);

    @include media-breakpoint-up(lg) {
        padding: 0;
    }
}

[id="nav"] {
    margin: 0 (-$container-padding-x * 0.5) rem-calc(8);
    letter-spacing: 0.05em;

    @include media-breakpoint-up(lg) {
        margin: 0;
        position: relative;
    }

    ul {
        @extend %listreset;
    }

    > ul {
        @include media-breakpoint-up(lg) {
            display: flex;
        }

        > li {
            border-top: $border-width solid rgba($white, 0.34);

            @include media-breakpoint-up(lg) {
                border-top: 0;
            }

            &:focus,
            &.hover {
                > a {
                    background-color: $body-bg;
                    color: $primary;

                    &:before {
                        transform: translateY(-50%) rotate(90deg);
                        background-image: escape-svg(url("data:image/svg+xml,<svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'><g  fill='none' fill-rule='evenodd'><path d='m9.29289322 5.70710678 1.41421358-1.41421356 11.7071068 11.70710678-11.7071068 11.7071068-1.41421358-1.4142136 10.29210678-10.2928932z' fill='#{$primary}' fill-rule='nonzero'/></g></svg>"));
                    }
                }

                .dropdown-menu {
                    display: block;
                }
            }

            &.active {
                color: $warning;
            }

            > a {
                @include animate (background-color color);
                display: block;
                position: relative;
                padding: rem-calc(15 60 10 23);

                @include media-breakpoint-up(lg) {
                    padding: rem-calc(7 20);
                    z-index: 9999;
                }

                @include media-breakpoint-up(xl) {
                    padding: rem-calc(7 45);
                }

                &:focus {
                    box-shadow: none;
                }

                &:before {
                    content: "";
                    position: absolute;
                    right: rem-calc(30);
                    top: 50%;
                    width: rem-calc(22);
                    height: rem-calc(22);
                    background-size: 100%;
                    transform: translateY(-50%);
                    background-image: escape-svg(url("data:image/svg+xml,<svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'><g  fill='none' fill-rule='evenodd'><path d='m9.29289322 5.70710678 1.41421358-1.41421356 11.7071068 11.70710678-11.7071068 11.7071068-1.41421358-1.4142136 10.29210678-10.2928932z' fill='#{$white}' fill-rule='nonzero'/></g></svg>"));

                    @include media-breakpoint-up(lg) {
                        display: none;
                    }
                }
            }
        }
    }

    .dropdown-menu {
        border-radius: 0;
        border: 0;
        padding: rem-calc(10 23);
        position: static;

        @include media-breakpoint-up(lg) {
            position: absolute;
            width: 100%;
            left: 0;
            display: none;
            padding: rem-calc(29 32 41);
            box-shadow: rem-calc(0 2 14) rgba($black, 0.2);
        }

        @include media-breakpoint-up(xl) {
            left: rem-calc(-27);
            width: 101.3%;
            padding-right: rem-calc(70);
        }

        .card-visual {
            max-width: rem-calc(200);
            margin: 0 auto rem-calc(20);

            @include media-breakpoint-up(md) {
                max-width: rem-calc(340);
            }

            @include media-breakpoint-up(lg) {
                margin: rem-calc(12 0 36);
                max-width: 100%;
            }
        }

        .btn {
            svg {
                &.arrow-right {
                    @include media-breakpoint-up(xl) {
                        margin-left: rem-calc(22);
                        margin-right: rem-calc(-40);
                    }
                }
            }
        }

        .menu-title {
            display: block;
            border-bottom: rem-calc(3) solid $gray-200;
            margin: rem-calc(0 0 18);
            font-size: rem-calc(25);
            font-weight: 500;
        }

        .sub-menu {
            font-size: rem-calc(21);
            margin: rem-calc(0 0 20);

            @include media-breakpoint-up(md) {
                column-count: 2;
                column-gap: rem-calc(10);
            }

            @include media-breakpoint-up(lg) {
                margin-bottom: 0;
                font-size: rem-calc(18);
            }

            @include media-breakpoint-up(xl) {
                font-size: rem-calc(21);
                width: 83%;
            }

            li {
                + li {
                    margin-top: rem-calc(8);
                }

                &.active {
                    a {
                        color: $warning;
                    }
                }
            }

            a {
                color: $primary;

                &:hover {
                    color: $success;
                }
            }
        }
    }

    .btn {
        &:focus,
        &:hover {
            color: $white;
        }
    }
}

.nav-bar-link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: rem-calc(20);
    letter-spacing: 0.05em;

    @include media-breakpoint-up(xl) {
        padding-left: rem-calc(43);
    }

    .list-social {
        margin-top: rem-calc(-8);
    }

    .text-with-svg {
        svg {
            margin-right: rem-calc(9);
        }
    }
}