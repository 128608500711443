.section-resource {
    padding: rem-calc(48 0);

    @include media-breakpoint-up(xl) {
        padding-bottom: rem-calc(100);
    }

    h2 {
        margin: rem-calc(0 0 20);

        @include media-breakpoint-up(lg) {
            margin-bottom: rem-calc(69);
        }
    }

    .container {
        max-width: 1600px;
    }
}

.swiper-resource {
    padding: rem-calc(0 30);

    @include media-breakpoint-up(lg) {
        padding: rem-calc(0 50);
    }

    .swiper-wrapper {
        display: flex;
    }

    .swiper-slide {
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
