// Typography
//

body {
    @include media-breakpoint-up(lg) {
        font-size: $font-size-base * 1.22; // 22px
        line-height: 1.45;
    }
}

h1,
.h1 {
    font-size: $h1-font-size;

    @include media-breakpoint-up(lg) {
        font-size: rem-calc(45);
    }
}

h2,
.h2 {
    font-size: $h2-font-size;
    letter-spacing: 0.05em;

    @include media-breakpoint-up(lg) {
        font-size: rem-calc(33);
    }
}

h3,
.h3 {
    font-size: $h3-font-size;
    font-weight: 600;

    @include media-breakpoint-up(lg) {
        font-size: rem-calc(30);
    }
}

h4,
.h4 {
    font-size: $h4-font-size;

    @include media-breakpoint-up(lg) {
        font-size: rem-calc(26);
    }
}

h5,
.h5 {
    font-size: $h5-font-size;

    @include media-breakpoint-up(lg) {
        font-size: rem-calc(24);
    }
}

a {
    @include animate(color box-shadow);

    &:focus {
        outline: none;
        box-shadow: $focus-ring-box-shadow;
    }
}

.lead {
    font-size: $lead-font-size;
    line-height: 1.33;

    @include media-breakpoint-up(lg) {
        font-size: $font-size-base * 1.83; // 33px
    }
}