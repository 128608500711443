.list-arrivals {
    @extend %listreset;
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    margin: rem-calc(-17 -5);
    font-size: $font-size-sm;
    letter-spacing: 0.04em;

    @include media-breakpoint-up(lg) {
        margin: rem-calc(-7 -5);
    }

    @include media-breakpoint-up(xl) {
        font-size: $font-size-base;
        margin: rem-calc(-17 -5);
    }

    li {
        padding: rem-calc(17 5);
        width: 50%;

        @include media-breakpoint-up(md) {
            width: 33.3%;
        }

        @include media-breakpoint-up(lg) {
            width: 50%;
            padding: rem-calc(7 5);
        }

        @include media-breakpoint-up(xl) {
            width: 33.3%;
            padding: rem-calc(17 5);
        }
    }

    a {
        color: $body-color;

        &:hover {
            color: $link-hover-color;
        }
    }

    img {
        margin: rem-calc(0 0 3);
    }

    .text {
        display: block;
    }
}