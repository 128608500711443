.section-hero {
    background-color: $primary;
    color: $white;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
        position: relative;
        z-index: 1;
        padding: rem-calc(80 0 92);
    }

    .wrap-bg {
        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background: radial-gradient(ellipse at 40% 90%, rgba($primary,0.27) 0%,  rgba(0, 93, 126, 0.8) 50%, rgba($primary,1) 100%);
        }
    }
}

.carousel-hero-wrap {
    position: relative;
    z-index: 1;
    margin: 0 (-$container-padding-x * 0.5);
    padding: rem-calc(30) $container-padding-x * 0.5 rem-calc(43);

    @include media-breakpoint-up(lg) {
        position: static;
        padding: 0;
        margin: 0;
    }
}

.carousel-hero {
    padding: rem-calc(0 0 0 64);

    @include media-breakpoint-up(lg) {
        padding-left: rem-calc(90);
    }

    .carousel-indicators {
        top: rem-calc(39);
        bottom: auto;
        margin: 0;
        display: block;
        width: rem-calc(30);
        left: rem-calc(14);

        @include media-breakpoint-up(xl) {
            left: rem-calc(10);
            top: rem-calc(44);
        }

        [data-bs-target] {
            display: block;
            border-radius: 50%;
        }
    }
}

.carousel-hero-content {
    font-size: rem-calc(20);
    line-height: 1.65;
    text-shadow: rem-calc(0 2 4) rgba($black, 0.5);
    letter-spacing: 0.03em;

    @include media-breakpoint-up(lg) {
        font-size: rem-calc(28);
        line-height: 1.43;
    }

    h1 {
        letter-spacing: 0.02em;
        margin: rem-calc(0 0 3);

        @include media-breakpoint-up(lg) {
            letter-spacing: 0.04em;
            margin-bottom: rem-calc(14);
        }
    }

    p {
        margin-bottom: rem-calc(33);

        @include media-breakpoint-up(lg) {
            margin-bottom: rem-calc(44);
        }
    }

    .category {
        display: block;
        text-transform: uppercase;
        margin: rem-calc(0 0 19 6);
        letter-spacing: 0.07em;
        text-shadow: rem-calc(0 2 2) rgba($black, 0.29);
        font: 700 #{rem-calc(15)}/#{1.2} $support-font-family-sans-serif;

        @include media-breakpoint-up(lg) {
            font-size: rem-calc(20);
        }
    }
}