.section-card-offset {
    padding: rem-calc(30 0);

    @include media-breakpoint-up(md) {
        max-width: 80%;
        margin: 0 auto;
    }

    @include media-breakpoint-up(lg) {
        max-width: 100%;
        margin: 0;
        padding: rem-calc(74 0);
    }
}

.section-card-offset-img {
    @include media-breakpoint-up(lg) {
        margin-right: rem-calc(-140);
    }

    img {
        max-width: none;
        width: 100%;
    }
}

.section-card-offset-body {
    padding: rem-calc(30) $container-padding-x * 0.5;
    margin: rem-calc(-30) $container-padding-x * 0.5 0;
    background-color: $green-100;
    line-height: 1.59;
    letter-spacing: 0.09em;

    @include media-breakpoint-up(lg) {
        padding: rem-calc(46 77 46 44);
        margin: rem-calc(0 25 0 -38);
    }

    h2 {
        margin: rem-calc(0 0 15);

        @include media-breakpoint-up(lg) {
            margin-bottom: rem-calc(41);
        }
    }

    p {
        margin: rem-calc(0 0 20);

        @include media-breakpoint-up(lg) {
            margin-bottom: rem-calc(49);
        }
    }
}