.section-post {
    padding: rem-calc(26 0 60);

    @include media-breakpoint-up(lg) {
        padding-bottom: rem-calc(80);
    }

    @include media-breakpoint-up(xl) {
        padding-bottom: rem-calc(160);
    }

    h2 {
        margin: rem-calc(0 0 20);

        @include media-breakpoint-up(lg) {
            margin-bottom: rem-calc(107);
        }
    }
}

.card-post {
    line-height: 1.56;

    @include media-breakpoint-up(md) {
        display: flex;
    }

    + .card-post {
        border-top: $border-width solid $gray-250;
        margin: rem-calc(30 0 0);
        padding: rem-calc(30 0 0);

        @include media-breakpoint-up(lg) {
            margin-top: rem-calc(78);
            padding-top: rem-calc(61);
        }
    }
}

.card-post-img {
    text-align: center;
    margin: rem-calc(0 0 30);

    @include media-breakpoint-up(md) {
        width: 27%;
        text-align: left;
        margin: 0;
    }
}

.card-post-body {
    line-height: 1.59;
    letter-spacing: 0.09em;

    @include media-breakpoint-up(md) {
        padding: rem-calc(10 0 0 30);
        width: 62%;
    }

    @include media-breakpoint-up(lg) {
        padding-left: rem-calc(38);
    }

    h3 {
        letter-spacing: 0.09em;
        margin-bottom: 0;
    }

    p {
        margin: rem-calc(0 0 20);

        @include media-breakpoint-up(lg) {
            margin-bottom: rem-calc(42);
        }
    }

    .location {
        font-size: $font-size-sm;
        display: block;
        margin: rem-calc(0 0 10);
        letter-spacing: 0.08em;

        @include media-breakpoint-up(lg) {
            font-size: $font-size-base;
        }
    }

    .btn {
        svg {
            &.arrow-right {
                margin-left: rem-calc(10);
            }
        }
    }
}