.section-teaser {
    background-color: $blue-100;
}

.section-teaser-img {
    margin: 0 (-$container-padding-x * 0.5);

    @include media-breakpoint-up(lg) {
        margin-left: rem-calc(-34);
    }

    @include media-breakpoint-up(xl) {
        margin-right: rem-calc(-79);
    }

    img {
        @include img-scale;
    }
}

.section-teaser-body {
    padding: rem-calc(30 0);
    line-height: 1.55;
    letter-spacing: 0.02em;

    @include media-breakpoint-up(lg) {
        padding: rem-calc(47 0);
        display: flex;
        align-items: center;
    }

    h2 {
        margin: rem-calc(0 0 23);
    }

    p {
        margin: rem-calc(0 0 41);
    }

    .frame {
        width: 100%;
    }
}