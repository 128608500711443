.banner {
    position: relative;
    min-height: rem-calc(225);
    display: flex;
    align-items: flex-end;
    color: $white;
    padding: rem-calc(30 0);
    text-shadow: rem-calc(0 2 4) rgba($black, 0.5);

    @include media-breakpoint-up(lg) {
        min-height: rem-calc(345);
        padding: rem-calc(55 0);
    }

    h1 {
        letter-spacing: 0.02em;

        @include media-breakpoint-up(lg) {
            font-size: rem-calc(75);
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .wrap-bg {
        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background: radial-gradient(ellipse at 40% 70%, rgba($primary,0.27) 0%, rgba(0, 93, 126, 0.8) 50%, rgba($primary,1) 100%);
        }
    }
}