.list-card-services {
    @extend %listreset;
    display: flex;
    flex-wrap: wrap;
    margin: rem-calc(-5);
    padding: rem-calc(41 0);

    @include media-breakpoint-up(lg) {
        padding: rem-calc(10 27 0);
        margin: rem-calc(-7 -5);
        max-width: rem-calc(395);
    }

    li {
        padding: rem-calc(5);
        width: 50%;
        display: flex;

        @include media-breakpoint-up(lg) {
            padding: rem-calc(7 5);
        }
    }
}

.card-services {
    width: 100%;
    background-color: rgba($white, 0.71);
    border-radius: rem-calc(8);
    text-align: center;
    padding: rem-calc(23 23 16);
    color: $body-color;
    font-size: rem-calc(20);
    line-height: 1.15;
    letter-spacing: 0.04em;

    @include media-breakpoint-up(lg) {
        line-height: 0.9;
        padding: rem-calc(20 23 24);
    }
}

.card-services-img {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: rem-calc(44);

    @include media-breakpoint-up(lg) {
        margin: rem-calc(0 0 3);
    }
}
