.alert {
    font-size: $font-size-sm;
    line-height: 1.2;
    letter-spacing: 0.155em;

    @include media-breakpoint-up(lg) {
        font-size: rem-calc(20);
        padding-left: rem-calc(60);
        padding-right: rem-calc(60);
    }

    @include media-breakpoint-up(xl) {
        padding-left: rem-calc(90);
        padding-right: rem-calc(90);
    }

    img,
    svg {
        margin: rem-calc(-2 5 0 0);
        flex-shrink: 0;
        width: rem-calc(16);

        @include media-breakpoint-up(lg) {
            margin: rem-calc(0 9 0 0);
            width: rem-calc(20);
        }
    }

    b {
        font-weight: 700;
    }

    .btn-close {
        position: absolute;
        top: rem-calc(10);
        right: rem-calc(10);
        background: none;

        @include media-breakpoint-up(lg) {
            @include size (rem-calc(24));
            top: 50%;
            right: rem-calc(10);
            transform: translateY(-50%);
        }

        @include media-breakpoint-up(xl) {
            @include size (rem-calc(28));
            right: rem-calc(52);
        }

        svg {
            width: 100%;
            height: auto;
        }
    }
}