.section-card {
    background-color: $gray-100;

    h2 {
        margin: rem-calc(0 0 30);

        @include media-breakpoint-up(lg) {
            margin-bottom: rem-calc(58);
        }
    }

    .container {
        @include media-breakpoint-up(lg) {
            display: flex;
            max-width: 1595px;
        }
    }
}

.section-card-content {
    padding: rem-calc(45 0);

    @include media-breakpoint-up(lg) {
        width: 72%;
        padding: rem-calc(45 40 80 0);
    }
}

.section-card-side {
    background-color: $blue-100;
    padding: rem-calc(30) $container-padding-x * 0.5;
    margin: 0 (-$container-padding-x * 0.5);

    @include media-breakpoint-up(lg) {
        width: calc(28% + 9999px);
        margin-right: -9999px;
        padding: rem-calc(50 calc(9999px + 12px) 58 42);
    }

    h3 {
        font-size: rem-calc(28);
        letter-spacing: 0.09em;
        margin-bottom: rem-calc(45);
        font-weight: 700;
    }
}

.card-info {
    background-color: $body-bg;
    border-radius: rem-calc(15 15 0 0);
    overflow: hidden;
    box-shadow: rem-calc(0 0 8) rgba($black, 0.04);
}

.card-info-img {
    position: relative;
    padding-top: 50.5%;

    img {
        @include img-scale;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.card-info-body {
    padding: rem-calc(10 15);
    color: $gray-800;
    font-size: $font-size-sm;
    line-height: 1.28;
    letter-spacing: 0.09em;

    @include media-breakpoint-up(lg) {
        font-size: $font-size-base;
        padding: rem-calc(21 23 50);
    }

    p {
        &:last-child {
            margin-bottom: 0;
        }
    }

    h3 {
        font-size: $font-size-base;
        margin: rem-calc(0 0 5);
        color: $primary;
        letter-spacing: 0.08em;

        @include media-breakpoint-up(lg) {
            font-size: rem-calc(20);
            margin-bottom: rem-calc(15);
        }
    }
}

.swiper-card-wrapper {
    position: relative;
    padding: rem-calc(0 30);

    @include media-breakpoint-up(lg) {
        padding: rem-calc(0 50 0 55);
    }

    .swiper {
        position: static;
    }
}

.swiper-card {
    .swiper-wrapper {
        display: flex;
    }

    .swiper-slide {
        height: auto;
        display: flex;
    }

    .swiper-button-next,
    .swiper-button-prev {
        margin-top: rem-calc(-38);
    }
}