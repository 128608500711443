.card-visual {
    border-radius: rem-calc(10);
    position: relative;
    overflow: hidden;
    margin: rem-calc(0 0 20);
    display: block;

    &:hover {
        .card-img {
            img {
                transform: scale($animate-scale) translate3d(0,0,0);
            }
        }
    }

    .card-img {
        position: relative;
        z-index: 1;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba($primary, 0.58);
            z-index: 2;
        }

        img {
            @include animate (transform, 0.6s);
            @include img-scale;
            display: block;
            transform: scale(1) translate3d(0,0,0);
        }
    }

    .card-body {
        position: absolute;
        z-index: 3;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: rem-calc(10);
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
    }

    .title {
        font-size: rem-calc(21);
        line-height: 1.1;
        font-weight: 500;
        letter-spacing: 0.05em;
    }
}