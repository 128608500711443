.section-text {
    padding: rem-calc(30 0);

    @include media-breakpoint-up(lg) {
        padding: rem-calc(41 0 103px);
    }

    .h2 {
        line-height: 1.33;
        letter-spacing: 0.008em;
        margin: rem-calc(0 0 20);

        @include media-breakpoint-up(lg) {
            margin-bottom: rem-calc(30);
        }
    }

    p {
        margin: rem-calc(0 0 20);

        @include media-breakpoint-up(lg) {
            margin-bottom: rem-calc(32);
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}