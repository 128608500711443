.btn {
    text-transform: uppercase;
    text-shadow: rem-calc(0 2 2) rgba($black, 0.25);
    letter-spacing: 0.06em;
    word-spacing: 0.1em;

    &.btn-danger {
        color: $white;
    }

    &.btn-info {
        color: $white;

        &:hover {
            color: $white;
        }
    }

    svg,
    img {
        &.arrow-right {
            margin: rem-calc(-5 -6 -2 0);
        }
    }

    svg {
        filter: drop-shadow(rem-calc(0 2 2) rgba($black, 0.25));
    }
}