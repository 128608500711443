input[type='search']::-webkit-search-cancel-button {
    -webkit-appearance: none;
}

.form-control {
    height: $input-height;
    padding-top: rem-calc(2);
    letter-spacing: 0.05em;
}

.header-search-form {
    font-size: rem-calc(15);
    color: $gray-700;
    max-width: 71%;
    margin: 0 auto;
    padding: rem-calc(21 0);

    @include media-breakpoint-up(lg) {
        max-width: 100%;
        margin: 0;
        padding: 0;
    }

    .form-control {
        border-radius: rem-calc(18);
    }

    .form-check-input {
        margin-top: 0;
    }

    .form-group {
        position: relative;
        margin: rem-calc(0 0 10);

        @include media-breakpoint-up(lg) {
            margin-bottom: rem-calc(8);
        }

        .submit {
            @include animate (color);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: rem-calc(20);
            background-color: transparent;
            border: 0;
            padding: 0;
            width: rem-calc(16);
            color: $primary;

            @include media-breakpoint-up(lg) {
                right: rem-calc(15);
            }

            &:hover {
                color: $success;
            }

            &:focus {
                outline: none;
                box-shadow: $form-check-input-focus-box-shadow;
            }
        }
    }

    .form-check-group {
        padding: rem-calc(0 12);
    }
}

.form-signup {
    @include media-breakpoint-up(md) {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
    }

    @include media-breakpoint-up(lg) {
        flex-wrap: nowrap;
    }

    > * {
        @include media-breakpoint-up(lg) {
            margin-left: rem-calc(20);
        }
    }

    .h4 {
        margin: rem-calc(0 0 15);
        letter-spacing: 0.07em;

        @include media-breakpoint-up(md) {
            width: 100%;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
            width: auto;
        }
    }

    .form-group {
        @include media-breakpoint-up(md) {
            display: flex;
            width: 100%;
        }

        @include media-breakpoint-up(lg) {
            margin-left: rem-calc(60);
            width: 48.2%;
        }
    }

    .form-control {
        margin: rem-calc(0 0 15);
        border-color: $input-bg;
        font-size: rem-calc(16);
        height: rem-calc(32);
        padding: rem-calc(3 16 0);
        letter-spacing: 0.02em;

        @include media-breakpoint-up(md) {
            margin-bottom: 0;
            width: calc(100% - 132px);
        }
    }

    .btn {
        border-radius: 0;
        font-size: rem-calc(14);
        text-shadow: none;
        letter-spacing: 0.05em;
        padding: rem-calc(7 17 5);
        width: rem-calc(112);

        @include media-breakpoint-up(md) {
            margin: rem-calc(0 0 0 20);
        }
    }
}