#wrapper {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

#main {
    flex-grow: 1;
}

svg {
    fill: currentColor;
}

.text-lg-success {
    @include media-breakpoint-up(lg) {
        color: $success !important;
    }
}

.text-lg-red {
    @include media-breakpoint-up(lg) {
        color: $red !important;
    }
}

.text-lg-primary {
    @include media-breakpoint-up(lg) {
        color: $primary !important;
    }
}

a.text-lg-primary {
    @include media-breakpoint-up(lg) {
        color: $primary !important;
    }

    &:hover {
        @include media-breakpoint-up(lg) {
            color: $link-hover-color !important;
        }
    }
}

.btn-close {
    @include animate (opacity);
}

.decor-title-center {
    position: relative;
    padding-bottom: rem-calc(4);
    text-align: center;

    &:before {
        content: "";
        position: absolute;
        left: 50%;
        top: 100%;
        transform: translateX(-50%);
        width: rem-calc(60%);
        height: rem-calc(3);
        background: currentColor;
        opacity: 0.63;
    }
}

.decor-title-side {
    text-align: center;

    &.text-success {
        span {
            &:before,
            &:after {
                background-color: $green-300;
            }
        }
    }

    &.text-primary {
        span {
            &:before,
            &:after {
                background-color: $info;
            }
        }
    }

    &.text-secondary {
        span {
            &:before,
            &:after {
                background-color: $green-300;
            }
        }
    }

    span {
        position: relative;
        padding: rem-calc(0 50);
        display: inline-block;
        vertical-align: top;

        &:after,
        &:before {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: rem-calc(30);
            height: rem-calc(6);
            border-radius: rem-calc(2);
        }

        &:before {
            left: 0;
        }

        &:after {
            right: 0;
        }
    }
}

.decor-title-side-right {
    &.text-success {
        span {
            &:after {
                background-color: $green-300;
            }
        }
    }

    &.text-primary {
        span {
            &:after {
                background-color: $info;
            }
        }
    }

    span {
        position: relative;
        padding: rem-calc(0 50 0 0);
        display: inline-block;
        vertical-align: top;

        &:after {
            content: "";
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: rem-calc(30);
            height: rem-calc(6);
            border-radius: rem-calc(2);
        }
    }
}

.text-with-svg {
    img,
    svg {
        margin: rem-calc(-5 5 0 0);
    }
}

.wrap-bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;

    img {
        @include img-scale;
    }
}

.section-main {
    padding: rem-calc(30 0);

    @include media-breakpoint-up(lg) {
        padding: rem-calc(67 0);
    }

    h2 {
        margin: rem-calc(0 0 20);

        @include media-breakpoint-up(lg) {
            margin-bottom: rem-calc(69);
        }
    }
}