:root {
    --swiper-navigation-size: 35px;
    --swiper-navigation-color: #9b9b9b;
    --swiper-navigation-sides-offset: 0;
}

.swiper-button-next,
.swiper-button-prev {
    @include animate (color);

    &:hover {
        color: $primary;
    }
}