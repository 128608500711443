[id="footer"] {
    background-color: $primary;
    color: $white;
    padding: rem-calc(30 0);
    font-size: rem-calc(17);
    text-align: center;
    letter-spacing: 0.13em;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(19);
        text-align: left;
    }

    @include media-breakpoint-up(lg) {
        padding: rem-calc(55 0);
    }

    a {
        color: inherit;

        &:hover {
            color: $warning;
        }
    }

    .h5 {
        margin: rem-calc(0 0 20);

        @include media-breakpoint-up(md) {
            margin: rem-calc(0 17 30);
        }
    }

    .container {
        max-width: 1312px;
    }

    .navbar-brand {
        margin: 0 auto rem-calc(10);
        width: rem-calc(250);

        @include media-breakpoint-up(md) {
            margin: rem-calc(0 0 21);
        }
    }

    .btn-list-vertical {
        .btn {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: inherit;
            font-family: inherit;
            text-transform: capitalize;
            text-shadow: none;
            padding: rem-calc(17 29 12 22);
            letter-spacing: inherit;

            &.btn-info {
                color: $black;
            }

            &.not-link {
                &:hover {
                    background-color: $info;
                }
            }

            + .btn {
                margin-top: rem-calc(13);
            }

            svg {
                filter: none;
            }
        }

        .list-social {
            color: $primary;
        }
    }
}

.footer-contact {
    margin: rem-calc(0 0 30);
    letter-spacing: 0.02em;

    &:last-child {
        @include media-breakpoint-up(md) {
            margin-bottom: 0;
        }
    }

    .text {
        display: flex;
        flex-wrap: wrap;
        margin: rem-calc(0 -5);
        justify-content: center;

        @include media-breakpoint-up(md) {
            justify-content: flex-start;
        }

        &:last-child {
            margin-top: rem-calc(3);
        }

        > * {
            margin: rem-calc(0 5);
        }
    }
}

.footer-bottom {
    padding: rem-calc(30 0 0);
    text-align: center;
    font-size: rem-calc(13);
    color: $gray-300;
    letter-spacing: 0.07em;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(15);
    }

    @include media-breakpoint-up(lg) {
        padding-top: rem-calc(64);
    }

    mark {
        background-color: transparent;
        color: $white;
    }

    a {
        color: $yellow;

        &:hover {
            color: $success;
        }

        &.with-separate {
            display: inline-block;
            position: relative;
            margin: rem-calc(0 0 0 7);

            &:before {
                content: "";
                position: absolute;
                left: rem-calc(-8);
                top: 50%;
                transform: translateY(-50%);
                width: $border-width;
                height: rem-calc(12);
                background: $gray-900;
            }
        }
    }
}