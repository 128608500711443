.accordion {
    p {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.accordion-item {
    border-width: rem-calc(2 0 0);

    &:not(:first-of-type) {
        border-top: $accordion-border-width solid $accordion-border-color;
    }

    &:last-child {
        border-bottom: $accordion-border-width solid $accordion-border-color;
    }
}

.accordion-button {
    flex-direction: row-reverse;
    justify-content: flex-end;
    font-weight: 700;
    font-size: rem-calc(20);
    padding-bottom: rem-calc(17);
    letter-spacing: 0.05em;

    @include media-breakpoint-up(lg) {
        font-size: rem-calc(24);
    }

    &:not(.collapsed) {
        box-shadow: none;
    }

    &:after {
        margin: rem-calc(0 21 0 0);
    }
}

.accordion-body {
    padding: rem-calc(0 0 46 58);
}